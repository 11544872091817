<template>
  <div class="app-form-date-picker">
    <label class="input-label" :for="uuid" :class="{ 'app-form-input-label--required': required }">{{ label }}</label>
    <DatePicker
      v-model="vModel"
      v-bind="$attrs"
      class="input-datepicker"
      :disabled-calendar-changer="disabledCalendarChanger"
      :disabled-date="disabledDate"
      :editable="false"
      :format="format"
      :get-year-panel="getYearPanel"
      :input-attr="{ ...ariaInputCustom, id: uuid, name, required }"
      :lang="langOptions"
      :popup-class="[
        'input-datepicker-popup',
        { 'input-datepicker-popup--panel-year-with-one-two-values': panelYearWithOneTwoValue },
      ]"
      :prefix-class="prefixClass"
      :show-time-panel="showTimePanel"
      :time-picker-options="timePickerOptions"
      :time-title-format="dateFormat"
      :title-format="dateFormat"
      :type="type"
      @close="handleOpenChange"
      @input-error="handleInputError"
      @pick="handlePickDate"
    >
      <template v-if="type === 'datetime'" #footer>
        <button :class="`${prefixClass}-btn ${prefixClass}-btn-text`" @click="toggleTimePanel">
          {{ showTimePanel ? 'Select Date' : 'Select Time' }}
        </button>
      </template>
    </DatePicker>

    <slot name="footer" />

    <Transition name="fade-down">
      <span v-show="inputError" class="input-error">{{ inputError }}</span>
    </Transition>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import uniqueId from '@/helpers/uniqueId';

export default {
  inheritAttrs: false,
  components: {
    DatePicker,
  },
  props: {
    aria: {
      type: Object,
      default: () => ({ ariaInput: {}, ariaMsg: {} }),
    },
    error: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    min: {
      type: String,
      default: () => null,
    },
    minTime: {
      type: String,
      default: '09:00',
    },
    max: {
      type: String,
      default: () => null,
    },
    maxTime: {
      type: String,
      default: '17:00',
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'datetime',
      validator: (value) => value === 'datetime' || value === 'date',
    },
    value: {
      type: [Array, Date],
      default: () => null,
    },
  },
  data() {
    return {
      dateFormat: 'D MMM YYYY',
      dateFullMonthFormat: 'D MMMM YYYY',
      inputError: '',
      langOptions: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthFormat: 'MMMM',
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      prefixClass: 'app-form-datepicker',
      showTimePanel: false,
      uuid: uniqueId(),
      yearsPanel: [],
    };
  },
  computed: {
    ariaInputCustom() {
      return {
        ...this.aria.ariaInput,
        'aria-invalid': this.inputError ? true : null,
      };
    },
    format() {
      if (this.type === 'date') {
        return this.dateFormat;
      }
      return `${this.dateFormat}, ${this.timePickerOptions.format}`;
    },
    panelYearWithOneTwoValue() {
      return this.yearsPanel.length <= 2;
    },
    timePickerOptions() {
      return {
        start: this.minTime,
        end: this.maxTime,
        step: '00:15',
        format: 'HH:mm',
      };
    },
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    error(err) {
      if (err) {
        this.inputError = err;
      } else {
        this.inputError = '';
      }
    },
  },
  methods: {
    disabledDate(date) {
      return date < this.getMinDate() || (this.max && date > this.max);
    },
    disabledCalendarChanger(date, type) {
      if (type === 'last-month' || type === 'last-year' || type === 'last-decade') {
        return date < this.getMinDate();
      }
      if (type === 'next-month' || type === 'next-year' || type === 'next-decade') {
        return this.max && date > this.max;
      }
      return false;
    },
    getMinDate() {
      return new Date((this.min ? new Date(this.min) : new Date()).setHours(0, 0, 0, 0));
    },
    getYearPanel(calendarDate) {
      const firstYear = Math.floor(calendarDate.getFullYear() / 10) * 10;
      const minDate = this.getMinDate();
      const maxYear = this.max ? new Date(this.max).getFullYear() : null;
      this.yearsPanel = Array.from({ length: 10 }, (_, i) => i + firstYear).filter(
        (year) => year >= minDate.getFullYear() && (!maxYear || year <= maxYear)
      );

      return this.chunkArr([...this.yearsPanel], 2);
    },
    chunkArr(arr, size) {
      return [...Array(Math.ceil(arr.length / size))].map((_) => arr.splice(0, size));
    },
    handleInputError(error) {
      this.inputError = error;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    handlePickDate() {
      this.showTimePanel = true;
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
  },
};
</script>

<style lang="scss">
$namespace: 'app-form-datepicker';
$default-color: #5b5d62;
$primary-color: #363636;
$input-border-color: #a2a2a2;
$input-color: $primary-color;
$input-border-radius: 1px;
$calendar-hover-background-color: mix(#fff, $primary-color, 90%);
$time-hover-background-color: mix(#fff, $primary-color, 90%);

@import '~vue2-datepicker/scss/index.scss';

.#{$namespace}-input {
  padding: 12px 16px;
  height: 48px;
  font-size: 16px;
  line-height: normal;
  box-shadow: none;
  cursor: default;

  &:hover,
  &:focus {
    border-color: $input-border-color;
  }

  &:focus {
    outline: initial;
  }

  &[aria-invalid] {
    border-color: #dc3545 !important;
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #a2a2a2;
  }
}

.#{$namespace}-icon-calendar,
.#{$namespace}-icon-clear {
  right: 16px;
}

.#{$namespace}-datepicker-main {
  font: 16px/1.5 'Futura PT', sans-serif;
}

.#{$namespace}-calendar {
  width: 270px;
}

.#{$namespace}-datepicker-popup {
  &.input-datepicker-popup--panel-year-with-one-two-values {
    .#{$namespace}-calendar-panel-year {
      .#{$namespace}-calendar-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .#{$namespace}-table-year {
        height: 112px;
      }
    }
  }
}

.#{$namespace}-calendar-header {
  .#{$namespace}-btn-icon-double-left,
  .#{$namespace}-btn-icon-double-right {
    &.disabled {
      display: none;
    }
  }
}

.#{$namespace}-calendar-header-label {
  font-size: 16px;

  .#{$namespace}-btn {
    font-size: 16px;
  }
}

.#{$namespace}-table {
  th {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.#{$namespace}-table-date {
  td {
    font-size: 14px;
  }
}

.#{$namespace}-time-option {
  &.active {
    font-weight: 500;
  }
}

.app-form-date-picker {
  .input-label {
    display: block;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #5b5d62;

    &--required {
      &::after {
        content: '*';
        margin-left: 1px;
      }
    }
  }

  .input-datepicker {
    width: 100%;
    position: relative;
  }

  .input-error {
    display: block;
    margin-top: 4px;
    font-size: 14px;
    line-height: normal;
    color: #dc3545;
    letter-spacing: 1px;
  }
}
</style>
